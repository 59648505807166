import React from 'react'
import cx from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'

import styles from './Spinner.module.scss'

type Props = {
  centered?: boolean
  className?: string
  fullscreen?: boolean
  size?: 'xs' | 'xl'
}

const Spinner = (props: Props) => {
  const { centered, className, fullscreen, size } = props
  const cn = cx(
    styles.spinner,
    {
      [styles.centered]: centered,
      [styles.fullscreen]: fullscreen,
      [styles.xs]: size === 'xs',
      [styles.xl]: size === 'xl',
    },
    className
  )
  return (
    <div className={cn}>
      <LoadingOutlined className={styles.icon} />
    </div>
  )
}

export default Spinner
