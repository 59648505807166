import { Button, Placeholder, Label } from 'constants/copy/touchables'

export const BUTTON_COPY: CopyRecord<Button> = {
  [Button.continue]: 'Continue',
  [Button.cancel]: 'Cancel',
  [Button.update]: 'Update',
  [Button.verify]: 'Verify',
  [Button.goBack]: 'Go Back',
  [Button.logout]: 'Log out',
  [Button.back]: 'Back',
  [Button.editInformation]: 'Edit Information',
  [Button.submit]: 'Submit',
  [Button.add]: 'Add',
  [Button.browseFile]: 'Browse File',
}

export const PLACEHOLDER_COPY: CopyRecord<Placeholder> = {
  [Placeholder.username]: 'Username',
  [Placeholder.password]: 'Password',
  [Placeholder.firstName]: 'First name',
  [Placeholder.lastName]: 'Last name',
  [Placeholder.mobileNumber]: '9196268534',
  [Placeholder.email]: 'iandcbautista@gmail.com',
  [Placeholder.relationshipToStudent]: 'Select relationship to student',
  [Placeholder.month]: 'Month',
  [Placeholder.year]: 'Year',
  [Placeholder.day]: 'Day',
  [Placeholder.placeOfBirth]: 'Input the city',
  [Placeholder.maritalStatus]: 'Select your marital status',
  [Placeholder.NIKNumber]: 'Input a valid NIK number',
  [Placeholder.residenceOwnership]: 'Status kepemilikan tempat tinggal',
  [Placeholder.province]: 'Find your current province',
  [Placeholder.city]: 'Find your current city',
  [Placeholder.barangay]: 'Input your barangay',
  [Placeholder.district]: 'Input your district',
  [Placeholder.address]: 'Input address',
  [Placeholder.addressLine1]:
    'Input address line 1 (e.g. 30E Blue Tower, Asul St.)',
  [Placeholder.addressLine2]: 'Input address line 2 (e.g. Blue Meadows Subd.)',
  [Placeholder.sourceOfIncome]: 'Choose a source of income',
  [Placeholder.businessName]: 'Input the name of employer/business',
  [Placeholder.businessAddress]:
    'Input address with province and city (e.g. 30E Blue Tower, Asul St.)',
  [Placeholder.NPWPNumber]: 'Input NPWP ID number',
  [Placeholder.SSSNumber]: 'Input SSS ID number',
  [Placeholder.TINNumber]: 'Input TIN ID number',
  [Placeholder.idType]: 'Select an ID type (School I.D. recommended)',
  [Placeholder.dragAndDrop]: 'Drag & drop your file here',
  [Placeholder.or]: 'or',
  [Placeholder.proofOfAddressType]: 'Select a Proof of address document',
  [Placeholder.privyId]: 'Masukan PrivyID Pelajar',
  [Placeholder.proofOfIncomeType]: 'Select a proof of income document',
}

export const LABEL_COPY: CopyRecord<Label> = {
  [Label.inputBirthday]: 'Birthday',
  [Label.mobileNumberInputFieldPrefix]: '+62',
  [Label.inputRelationshipToStudent]: 'Relationship to student',
  [Label.inputReligion]: 'Religion',
  [Label.inputNIKNumber]: 'NIK number',
  [Label.inputResidenceOwnership]: 'Status kepemilikan tempat tinggal',
  [Label.inputProvince]: 'Current Province',
  [Label.inputCity]: 'Current City',
  [Label.inputBarangay]: 'Barangay',
  [Label.inputDistrict]: 'District',
  [Label.inputAddress]: 'Address',
  [Label.inputAddressLine1]: 'Address Line 1',
  [Label.inputAddressLine1Info]:
    'House no. / Unit no. / Building name, Street name',
  [Label.inputAddressLine2]: 'Address Line 2',
  [Label.inputAddressLine2Info]:
    'Sitio / Area / Cluster / Subdivision / District',
  [Label.inputSourceOfIncome]: 'Choose a source of income',
  [Label.inputPosition]: 'Current position',
  [Label.inputIndustry]: 'Current industry',
  [Label.inputBusinessName]: 'Name of Employer/Business',
  [Label.inputBusinessAddress]: 'Employer/Business Address',
  [Label.inputBusinessAddressInfo]:
    'Unit no. / Building name, Street name, Barangay, City, Province',
  [Label.inputEmployerPhoneOrBusinessPhone]: 'Employer/Business Phone',
  [Label.inputStartDateJob]: 'Start date in current job',
  [Label.inputMonthlyIncomeLabel]: 'Gross monthly income',
  [Label.inputTINNumberLabel]: 'TIN ID number (optional)',
  [Label.inputSSSNumberLabel]: 'SSS ID number (optional)',
  [Label.inputNPWPNumberLabel]: 'NPWP ID number (optional)',
  [Label.inputProofOfIncomeLabel]: 'Proof of income document (optional)',
  [Label.selectIdType]: 'Select an ID type',
  [Label.howToAddPhoto]: 'How do I add a photo?',
  [Label.selectProofOfAddressType]: 'Select a Proof of address document',
  [Label.inputPrivyId]: 'Masukan PrivyID Pelajar',
  [Label.selectProofOfIncomeType]: 'Select a proof of income document',
}
