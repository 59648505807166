import React from 'react'
import cx from 'classnames'
import { Skeleton as AntdSkeleton } from 'antd'
import { SkeletonProps } from 'antd/lib/skeleton'

import styles from './Skeleton.module.scss'

type Props = {
  active?: boolean
  avatar?: SkeletonProps['avatar']
  className?: string
  paragraph?: SkeletonProps['paragraph']
  title?: SkeletonProps['title']
  vCenter?: boolean
}

const Skeleton = (props: Props & SkeletonProps) => {
  const {
    active = true,
    avatar = false,
    className,
    paragraph = false,
    title = false,
    vCenter,
    ...skeletonProps
  } = props
  const cn = cx(
    styles.skeleton,
    {
      [styles.vCenter]: vCenter,
    },
    className
  )
  return (
    <AntdSkeleton
      {...skeletonProps}
      active={active}
      className={cn}
      avatar={avatar}
      paragraph={paragraph}
      title={title}
    />
  )
}

export default Skeleton
