import React, { useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { AuthContext } from 'hooks/useAuth'
import { saveToken, deleteToken, getToken } from 'utils/auth'
import { deleteTransactionsId } from 'utils/transactionId'
import { Routes } from 'constants/routes'

import Pages from 'pages'
import DocumentHead from 'containers/DocumentHead'

const App: React.FC = () => {
  const history = useHistory()
  const [isAuth, setIsAuth] = useState(Boolean(getToken()))

  const login = useCallback(
    (token: Token, next?: Routes) => {
      saveToken(token)
      setIsAuth(true)
      if (next) history.push(next)
    },
    [history]
  )

  const logout = useCallback(() => {
    deleteToken()
    deleteTransactionsId()
    setIsAuth(false)
  }, [])

  const AuthProvider = useMemo(
    () => ({ isAuth, login, logout }),
    [isAuth, login, logout]
  )

  return (
    <AuthContext.Provider value={AuthProvider}>
      <DocumentHead />
      <Pages />
    </AuthContext.Provider>
  )
}

export default App
