export enum SessionTimeout {
  infoCountdown = 'sessionTimeout.infoCountdown',
}

export enum Meta {
  title = 'meta.title',
}

export enum Topnav {
  title = 'topNav.title',
}

export enum SecondNav {
  paymentTerms = 'secondNav.paymentTerms',
  applicationForm = 'secondNav.applicationForm',
  reviewAndSubmit = 'secondNav.reviewAndSubmit',
}

export enum PasswordForm {
  ruleLowercase = 'formPassword.ruleLowercase',
  ruleUppercase = 'formPassword.ruleUppercase',
  ruleNumber = 'formPassword.ruleNumber',
  ruleSpecial = 'formPassword.ruleSpecial',
  ruleLength = 'formPassword.ruleLength',
}
