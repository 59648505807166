import React from 'react'
import { Helmet } from 'react-helmet'

import useTranslation from 'hooks/useTranslation'

const DocumentHead = () => {
  const { t, copy } = useTranslation()
  return (
    <Helmet>
      <meta name="description" content={t(copy.Components.Meta.title)} />
      <title>{t(copy.Components.Meta.title)}</title>
    </Helmet>
  )
}

export default DocumentHead
