export enum Routes {
  index = '/',
  login = '/login',
  createPassword = '/create-password',

  eligible = '/eligible',
  ineligible = '/ineligible',
  existingUser = '/user',
  existingUserWithActiveLoan = '/user-ln-active',
  existingUserWithPendingLoan = '/user-ln-pnd',
  requestedAmount = '/requested-amount',
  selectLoanProduct = '/select-loan-product',

  loanApplication = '/loan-application',
  studentDetails = '/loan-application/student-details',
  guardianInfo = '/loan-application/guardian-info',
  guarantorInfo = '/loan-application/guarantor-info',

  loanSubmission = '/loan-submission',
  applicationReview = '/loan-submission/application-review',
  submissionSuccess = '/loan-submission/submission-success',
}

export type RouteParams = {
  eligible: {
    transaction_id: string
    otp?: string
    updateMobile?: string
    verifyPhone?: string
    createPassword?: string
    forUpdateMobile?: string
    existingMobile?: string
    userStatus?: UserStatus
    forUpdateMobileSuccess?: string
    access?: string
    userTypeGuardian?: string
  }
  ineligible: {
    transaction_id: string
  }
  existingUser: {
    transaction_id: string
  }
  existingUserWithLoan: {
    transaction_id: string
  }
  requestedAmount: {
    requestedAmount: string
    transaction_id: string
  }
  selectLoanProduct: {
    requestedAmount: string
    transaction_id: string
  }
  studentDetails: {
    uploadId: string
    uploadProofOfAddress: string
    addPrivyId: string
    uploadSelfie: string
    uploadBorrowerSchoolAssessment: string
  }
}
