import {
  SessionTimeout,
  Meta,
  Topnav,
  SecondNav,
  PasswordForm,
} from 'constants/copy/components'
import { APP_NAME } from 'constants/countryInfo'

export const SESSION_TIMEOUT_COPY: CopyRecord<SessionTimeout> = {
  [SessionTimeout.infoCountdown]: `Kamu sepertinya sedang tidak aktif. Untuk alasan keamanan, dan kamu akan otomatis keluar. Sesi kamu akan berakhir pada {{min}} menit {{secs}} detik. Silahkan klik "Tetap Masuk" untuk melanjutkan atau "Keluar" jika ingin selesai.`,
}

export const META_COPY: CopyRecord<Meta> = {
  [Meta.title]: `${APP_NAME} Integrations`,
}

export const TOP_NAV_COPY: CopyRecord<Topnav> = {
  [Topnav.title]: 'Tuition Installment Plans',
}

export const SECOND_NAV_COPY: CopyRecord<SecondNav> = {
  [SecondNav.paymentTerms]: 'Your Payment Terms',
  [SecondNav.applicationForm]: 'Fill out application form',
  [SecondNav.reviewAndSubmit]: 'Review & Submit',
}

export const PASSWORD_FORM_COPY: CopyRecord<PasswordForm> = {
  [PasswordForm.ruleLowercase]: 'Contains at least one lowercase letter',
  [PasswordForm.ruleUppercase]: 'Contains at least one uppercase letter',
  [PasswordForm.ruleNumber]: 'Contains at least one number',
  [PasswordForm.ruleSpecial]: 'Contains at least one special character',
  [PasswordForm.ruleLength]: 'At least 8 characters long',
}
