import set from 'lodash/set'

import * as idCopyModules from 'constants/copy/id'
import * as phCopyModules from 'constants/copy/ph'

import * as ComponentsCopy from 'constants/copy/components'
import * as InputsCopy from 'constants/copy/inputs'
import * as PagesCopy from 'constants/copy/pages'
import * as TouchablesCopy from 'constants/copy/touchables'

const formatCopyValues = (
  copyModules: Record<string, Record<string, Record<string, string>>>
) => {
  const copyValues = {}
  Object.values(copyModules).forEach((modules) => {
    Object.values(modules).forEach((values) => {
      Object.entries(values).forEach(([key, value]) => {
        set(copyValues, key, value)
      })
    })
  })
  return copyValues
}

export const COPY_RESOURCES = {
  id: formatCopyValues(idCopyModules),
  ph: formatCopyValues(phCopyModules),
}

const COPY = {
  Components: ComponentsCopy,
  Inputs: InputsCopy,
  Pages: PagesCopy,
  Touchables: TouchablesCopy,
}

export default COPY
