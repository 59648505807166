import { COUNTRY } from 'constants/config'

const getAppName = () => {
  switch (COUNTRY) {
    case 'PH':
      return 'Bukas'
    case 'ID':
      return 'Danacita'
  }
}

const getCountryName = () => {
  switch (COUNTRY) {
    case 'PH':
      return 'Philippines'
    case 'ID':
      return 'Indonesia'
  }
}

const getLocale = () => {
  switch (COUNTRY) {
    case 'PH':
      return 'ph'
    case 'ID':
      return 'id'
  }
}

export const APP_NAME = getAppName()
export const COUNTRY_NAME = getCountryName()
export const LOCALE = getLocale()
