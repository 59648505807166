import React from 'react'
import {
  Route,
  Switch,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom'

import useAuth from 'hooks/useAuth'
import { Routes } from 'constants/routes'

const PrivateRouteLayoutSwitch = (
  props: RouteProps & { layoutComponent: React.FC<RouteComponentProps> }
) => {
  const { children, layoutComponent: LayoutComponent, ...routeProps } = props
  const { isAuth } = useAuth()
  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) =>
        isAuth ? (
          <LayoutComponent {...routeComponentProps}>
            <Switch>{children}</Switch>
          </LayoutComponent>
        ) : (
          <Redirect
            to={{
              pathname: Routes.login,
              state: { from: routeComponentProps.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRouteLayoutSwitch
