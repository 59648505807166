import React, { lazy, Suspense } from 'react'
import { Router, Switch, Route } from 'react-router-dom'

import history from 'utils/history'
import { Routes } from 'constants/routes'

import { PublicRoute, PrivateRouteLayoutSwitch } from 'containers/Router'
import DashboardLayout from 'containers/DashboardLayout'
import Spinner from 'components/Spinner'

// Public Pages
const Login = lazy(() => import('./Login'))

// Protected Pages
// const Home = lazy(() => import('./Home'))
const Eligible = lazy(() => import('./Eligible'))
const Ineligible = lazy(() => import('./Ineligible'))
const ExistingUser = lazy(() => import('./ExistingUser'))
const ExistingUserWithActiveLoan = lazy(
  () => import('./ExistingUserWithActiveLoan')
)
const ExistingUserWithPendingLoan = lazy(
  () => import('./ExistingUserWithPendingLoan')
)
const RequestedAmount = lazy(() => import('./RequestedAmount'))
const SelectLoanProduct = lazy(() => import('./SelectLoanProduct'))
const LoanApplication = lazy(() => import('./LoanApplication'))
const LoanSubmission = lazy(() => import('./LoanSubmission'))

const Pages: React.FC = () => {
  return (
    <Suspense fallback={<Spinner fullscreen size="xl" />}>
      <Router history={history}>
        <Switch>
          <PublicRoute path={Routes.login} component={Login} />
          <PublicRoute path={Routes.eligible} component={Eligible} />
          <PublicRoute path={Routes.ineligible} component={Ineligible} />
          <PublicRoute path={Routes.existingUser} component={ExistingUser} />
          <PublicRoute
            path={Routes.existingUserWithActiveLoan}
            component={ExistingUserWithActiveLoan}
          />
          <PublicRoute
            path={Routes.existingUserWithPendingLoan}
            component={ExistingUserWithPendingLoan}
          />

          <PrivateRouteLayoutSwitch layoutComponent={DashboardLayout}>
            <Route
              exact
              path={Routes.requestedAmount}
              component={RequestedAmount}
            />
            <Route
              exact
              path={Routes.selectLoanProduct}
              component={SelectLoanProduct}
            />
            <Route path={Routes.loanApplication} component={LoanApplication} />
            <Route path={Routes.loanSubmission} component={LoanSubmission} />
          </PrivateRouteLayoutSwitch>
        </Switch>
      </Router>
    </Suspense>
  )
}

export default Pages
