export enum Button {
  continue = 'touchables.button.continue',
  cancel = 'touchables.button.cancel',
  update = 'touchables.button.update',
  verify = 'touchables.button.verify',
  goBack = 'touchables.button.goBack',
  logout = 'touchables.button.logout',
  back = 'touchables.button.back',
  editInformation = 'touchables.button.editInformation',
  submit = 'touchables.button.submit',
  add = 'touchables.button.add',
  browseFile = 'touchables.button.browseFile',
}

export enum Placeholder {
  username = 'touchables.placeholder.username',
  password = 'touchables.placeholder.password',
  firstName = 'touchables.placeholder.firstName',
  lastName = 'touchables.placeholder.lastName',
  mobileNumber = 'touchables.placeholder.mobileNumber',
  email = 'touchables.placeholder.email',
  relationshipToStudent = 'touchables.placeholder.relationshipToStudent',
  month = 'touchables.placeholder.month',
  year = 'touchables.placeholder.year',
  day = 'touchables.placeholder.day',
  placeOfBirth = 'touchables.placeholder.placeOfBirth',
  maritalStatus = 'touchables.placeholder.maritalStatus',
  NIKNumber = 'touchables.placeholder.NIKNumber',
  residenceOwnership = 'touchables.placeholder.residenceOwnership',
  province = 'touchables.placeholder.province',
  city = 'touchables.placeholder.city',
  barangay = 'touchables.placeholder.barangay',
  district = 'touchables.placeholder.district',
  address = 'touchables.placeholder.address',
  addressLine1 = 'touchables.placeholder.addressLine1',
  addressLine2 = 'touchables.placeholder.addressLine2',
  sourceOfIncome = 'touchables.placeholder.sourceOfIncome',
  businessName = 'touchables.placeholder.businessName',
  businessAddress = 'touchables.placeholder.businessAddress',
  TINNumber = 'touchables.placeholder.TINNumber',
  SSSNumber = 'touchables.placeholder.SSSNumber',
  NPWPNumber = 'touchables.placeholder.NPWPNumber',
  idType = 'touchables.placeholder.idType',
  dragAndDrop = 'touchables.placeholder.dragAndDrop',
  or = 'touchables.placeholder.or',
  proofOfAddressType = 'touchables.placeholder.proofOfAddressType',
  privyId = 'touchables.placeholder.privyId',
  proofOfIncomeType = 'touchables.placeholder.proofOfIncomeType',
}

export enum Label {
  inputBirthday = 'touchables.label.inputBirthday',
  mobileNumberInputFieldPrefix = 'touchables.label.mobileNumberInputFieldPrefix',
  inputRelationshipToStudent = 'touchables.label.inputRelationshipToStudent',
  inputReligion = 'touchables.label.inputReligion',
  inputNIKNumber = 'touchables.label.inputNIKNumber',
  inputResidenceOwnership = 'touchables.label.inputResidenceOwnership',
  inputProvince = 'touchables.label.inputProvince',
  inputCity = 'touchables.label.inputCity',
  inputBarangay = 'touchables.label.inputBarangay',
  inputDistrict = 'touchables.label.inputDistrict',
  inputAddress = 'touchables.label.inputAddress',
  inputAddressLine1 = 'touchables.label.inputAddressLine1',
  inputAddressLine1Info = 'touchables.label.inputAddressLine1Info',
  inputAddressLine2 = 'touchables.label.inputAddressLine2',
  inputAddressLine2Info = 'touchables.label.inputAddressLine2Info',
  inputSourceOfIncome = 'touchables.label.inputSourceOfIncome',
  inputPosition = 'touchables.label.inputPosition',
  inputIndustry = 'touchables.label.inputIndustry',
  inputBusinessName = 'touchables.label.inputBusinessName',
  inputBusinessAddress = 'touchables.label.inputBusinessAddress',
  inputBusinessAddressInfo = 'touchables.label.inputBusinessAddressInfo',
  inputEmployerPhoneOrBusinessPhone = 'touchables.label.inputEmployerPhoneOrBusinessPhone',
  inputStartDateJob = 'touchables.label.inputStartDateJob',
  inputMonthlyIncomeLabel = 'touchables.label.inputMonthlyIncomeLabel',
  inputTINNumberLabel = 'touchables.label.inputTINNumberLabel',
  inputSSSNumberLabel = 'touchables.label.inputSSSNumberLabel',
  inputNPWPNumberLabel = 'touchables.label.inputNPWPNumberLabel',
  inputProofOfIncomeLabel = 'touchables.label.inputProofOfIncomeLabel',
  selectIdType = 'touchables.label.selectIdType',
  howToAddPhoto = 'touchables.label.howToAddPhoto',
  selectProofOfAddressType = 'touchables.label.selectProofOfAddressType',
  inputPrivyId = 'touchables.label.inputPrivyId',
  selectProofOfIncomeType = 'touchables.label.selectProofOfIncomeType',
}
