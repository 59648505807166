import session, { SessionStorageKey } from './sessionStorage'

export const getTransactionsId = () => {
  const transactionId = session.getItem(SessionStorageKey.transactionId)
  if (transactionId) return transactionId
}

export const saveTransactionsId = (transactionId: string) => {
  session.setItem(SessionStorageKey.transactionId, transactionId)
}

export const deleteTransactionsId = () =>
  session.removeItem(SessionStorageKey.transactionId)
