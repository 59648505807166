import React from 'react'
import cx from 'classnames'

import styles from './Loader.module.scss'
import Skeleton from 'components/Skeleton'
import Spinner from 'components/Spinner'

type Props = {
  className?: string
}

const Loader = (props: Props) => {
  const { className } = props
  return (
    <div className={cx(styles.loader, className)}>
      <div className={styles.header}>
        <Skeleton
          title={{ width: '15%', className: styles.headerTitle }}
          paragraph={{
            rows: 1,
            width: '30%',
            className: styles.headerSubtitle,
          }}
          className={styles.headerSkeleton}
        />
      </div>
      <Spinner centered size="xl" />
    </div>
  )
}

export default Loader
