import React, { Suspense } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import Loader from './Loader'
import styles from './DashboardLayout.module.scss'

type Props = {
  children?: React.ReactNode
}

const DashboardLayout: React.FC<RouteComponentProps> = (
  props: RouteComponentProps & Props
) => {
  const { children } = props

  return (
    <div className={styles.dashboardLayout}>
      <div className={styles.content}>
        <main className={styles.main}>
          <Suspense fallback={<Loader />}>{children}</Suspense>
        </main>
      </div>
    </div>
  )
}

export default DashboardLayout
