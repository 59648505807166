import { useTranslation as useTranslationHook } from 'react-i18next'

import COPY from 'constants/copy'

const useTranslation = () => {
  const { t } = useTranslationHook()
  return { t, copy: COPY }
}

export default useTranslation
