export enum Login {
  loginButton = 'pages.login.loginButton',
  alert = 'pages.login.alert',
}

export enum CreatePassword {
  createPasswordButton = 'pages.createPaswword.createPasswordButton',
  heading = 'pages.createPaswword.heading',
}

export enum Home {
  title = 'pages.home.title',
  logoutButton = 'pages.home.logoutButton',
}

export enum Eligible {
  title = 'pages.eligible.title',
  eligibleInfo = 'pages.eligible.eligibleInfo',
  tenorInfoHeading = 'pages.eligible.tenorInfoHeading',
  tenorInfoText = 'pages.eligible.tenorInfoText',
  paymentOptionsInfoHeading = 'pages.eligible.paymentOptionsInfoHeading',
  paymentOptionsInfoText = 'pages.eligible.paymentOptionsInfoText',
  thanksForSharingText = 'pages.eligible.thanksForSharingText',
  confirmationHeading = 'pages.eligible.confirmationHeading',
  fullName = 'pages.eligible.fullName',
  mobileNumber = 'pages.eligible.mobileNumber',
  notYou = 'pages.eligible.notYou',
  backToPortal = 'pages.eligible.backToPortal',

  modalUpdateMobileTitle = 'pages.eligible.modalUpdateMobileTitle',
  inputMobileNumberLabel = 'pages.eligible.inputMobileNumberLabel',
  inputMobileNumberButton = 'pages.eligible.inputMobileNumberButton',

  modalVerifyPhoneTitle = 'pages.eligible.modalVerifyPhoneTitle',
  modalVerifyPhoneContent = 'pages.eligible.modalVerifyPhoneContent',
  modalVerifyPhoneSendVerificationCodeButton = 'pages.eligible.modalVerifyPhoneSendVerificationCodeButton',
  modalVerifyPhoneEditNumberButton = 'pages.eligible.modalVerifyPhoneEditNumberButton',

  modalOTPTitle = 'pages.eligible.modalOTPTitle',
  modalOTPSubtitle = 'pages.eligible.modalOTPSubtitle',
  modalOTPContent = 'pages.eligible.modalOTPContent',
  modalOTPTimerAndResendInfo = 'pages.eligible.modalOTPTimerAndResendInfo',

  modalUserTypeGuardianTitle = 'pages.eligible.modalUserTypeGuardianTitle',
  modalUserTypeGuardianInfo = 'pages.eligible.modalUserTypeGuardianInfo',
  modalUserTypeGuardianDetail1 = 'pages.eligible.modalUserTypeGuardianDetail1',
  modalUserTypeGuardianDetail2 = 'pages.eligible.modalUserTypeGuardianDetail2',
  modalUserTypeGuardianButton = 'pages.eligible.modalUserTypeGuardianButton',
}

export enum Ineligible {
  title = 'pages.ineligible.title',
  ineligibleInfo = 'pages.ineligible.ineligibleInfo',
  ineligibleDetail = 'pages.ineligible.ineligibleDetail',
  tenorInfoHeading = 'pages.ineligible.tenorInfoHeading',
  tenorInfoText = 'pages.ineligible.tenorInfoText',
  paymentOptionsInfoHeading = 'pages.ineligible.paymentOptionsInfoHeading',
  paymentOptionsInfoText = 'pages.ineligible.paymentOptionsInfoText',
  backToSchoolPortal = 'pages.ineligible.backToSchoolPortal',
}

export enum ExistingUser {
  title = 'pages.ineligible.title',
  welcomeBack = 'pages.ineligible.welcomeBack',
  existingUserInfo = 'pages.ineligible.existingUserInfo',
  existingUserWithActiveLoanInfo = 'pages.ineligible.existingUserWithActiveLoanInfo',
  existingUserWithPendingLoanInfo = 'pages.ineligible.existingUserWithPendingLoanInfo',
  tenorInfoHeading = 'pages.ineligible.tenorInfoHeading',
  tenorInfoText = 'pages.ineligible.tenorInfoText',
  paymentOptionsInfoHeading = 'pages.ineligible.paymentOptionsInfoHeading',
  paymentOptionsInfoText = 'pages.ineligible.paymentOptionsInfoText',
  goToBALoginPage = 'pages.ineligible.goToBALoginPage',
}

export enum RequestedAmount {
  title = 'pages.requestedAmount.title',
  label = 'pages.requestedAmount.label',
  placeholder = 'pages.eligible.placeholder',
}

export enum SelectLoanProduct {
  title = 'pages.selectLoanProduct.title',
  recommended = 'pages.selectLoanProduct.recommended',
  tenor = 'pages.selectLoanProduct.tenor',
  installment = 'pages.selectLoanProduct.installment',
  interest = 'pages.selectLoanProduct.interest',
  quickAndEasy = 'pages.selectLoanProduct.quickAndEasy',
  zeroInterest = 'pages.selectLoanProduct.zeroInterest',
  longerRepyment = 'pages.selectLoanProduct.longerRepyment',
  lowInterest = 'pages.selectLoanProduct.lowInterest',
  summary = 'pages.selectLoanProduct.summary',
  totalTuitionFee = 'pages.selectLoanProduct.totalTuitionFee',
  totalTuitionFeeValue = 'pages.selectLoanProduct.totalTuitionFeeValue',
  montlyInstallments = 'pages.selectLoanProduct.montlyInstallments',
  montlyInstallmentsValue = 'pages.selectLoanProduct.montlyInstallmentsValue',
  interestPerMonth = 'pages.selectLoanProduct.interestPerMonth',
  interestPerMonthValue = 'pages.selectLoanProduct.interestPerMonthValue',
  paymentPerMonth = 'pages.selectLoanProduct.paymentPerMonth',
  paymentPerMonthValue = 'pages.selectLoanProduct.paymentPerMonthValue',
  regularInstallment = 'pages.selectLoanProduct.regularInstallment',
}

export enum LoanApplication {
  studentDetails = 'pages.loanApplication.studentDetails',
  guardianInfo = 'pages.loanApplication.guardianInfo',
  guarantorInfo = 'pages.loanApplication.guarantorInfo',

  studentDetailsTitle = 'pages.loanApplication.studentDetailsTitle',
  studentDetailsSubtitle = 'pages.loanApplication.studentDetailsSubtitle',
  studentDetailsInputFirstNameLabel = 'pages.loanApplication.studentDetailsInputFirstNameLabel',
  studentDetailsInputLastNameLabel = 'pages.loanApplication.studentDetailsInputLastNameLabel',
  studentDetailsInputGenderLabel = 'pages.loanApplication.studentDetailsInputGenderLabel',
  studentDetailsInputPlaceOfBirthLabel = 'pages.loanApplication.studentDetailsInputPlaceOfBirthLabel',
  studentDetailsInputMaritalStatusLabel = 'pages.loanApplication.studentDetailsInputMaritalStatusLabel',
  studentDetailsInputReligionPlaceholder = 'pages.loanApplication.studentDetailsInputReligionPlaceholder',
  studentDetailsInputEmailLabel = 'pages.loanApplication.studentDetailsInputEmailLabel',
  studentDetailsInputValidIdLabel = 'pages.loanApplication.studentDetailsInputValidIdLabel',
  studentDetailsInputValidIdInfo = 'pages.loanApplication.studentDetailsInputValidIdInfo',
  studentDetailsInputSelfieLabel = 'pages.loanApplication.studentDetailsInputSelfieLabel',
  studentDetailsInputSchoolAssessmentLabel = 'pages.loanApplication.studentDetailsInputSchoolAssessmentLabel',
  studentDetailsInputValidIdButton = 'pages.loanApplication.studentDetailsInputValidIdButton',
  studentDetailsInputSelfieButton = 'pages.loanApplication.studentDetailsInputSelfieButton',
  studentDetailsInputSchoolAssessmentButton = 'pages.loanApplication.studentDetailsInputSchoolAssessmentButton',
  studentDetailsInputPrivyIdLabel = 'pages.loanApplication.studentDetailsInputPrivyIdLabel',
  studentDetailsInputPrivyIdInfo = 'pages.loanApplication.studentDetailsInputPrivyIdInfo',
  studentDetailsInputPrivyIdButton = 'pages.loanApplication.studentDetailsInputPrivyIdButton',

  studentDetailsAddAddressTitle = 'pages.loanApplication.studentDetailsAddAddressTitle',
  studentDetailsAddAddressSubtitle = 'pages.loanApplication.studentDetailsAddAddressSubtitle',
  studentDetailsAddAddressInfo = 'pages.loanApplication.studentDetailsAddAddressInfo',
  studentDetailsInputStudentAddressSameAsIdLabel = 'pages.loanApplication.studentDetailsInputStudentAddressSameAsIdLabel',
  studentDetailsInputProofOfAddressDocumentLabel = 'pages.loanApplication.studentDetailsInputProofOfAddressDocumentLabel',
  studentDetailsInputProofOfAddressDocumentInfo = 'pages.loanApplication.studentDetailsInputProofOfAddressDocumentInfo',
  studentDetailsInputProofOfAddressDocumentButton = 'pages.loanApplication.studentDetailsInputProofOfAddressDocumentButton',
  studentDetailsInputNearestLandmarkLabel = 'pages.loanApplication.studentDetailsInputNearestLandmarkLabel',
  studentDetailsInputNearestLandmarkPlaceholder = 'pages.loanApplication.studentDetailsInputNearestLandmarkPlaceholder',
  studentDetailsInputNearestLandmarkInfo = 'pages.loanApplication.studentDetailsInputNearestLandmarkInfo',

  studentDetailsExpeditedTitle = 'pages.loanApplication.studentDetailsExpeditedTitle',
  studentDetailsExpeditedSubtitle = 'pages.loanApplication.studentDetailsExpeditedSubtitle',

  guardianInfoTitle = 'pages.loanApplication.guardianInfoTitle',
  guardianInfoSubtitle = 'pages.loanApplication.guardianInfoSubtitle',
  guardianInfoInfo = 'pages.loanApplication.guardianInfoInfo',
  guardianInfoInputRelationshipLabel = 'pages.loanApplication.guardianInfoInputRelationshipLabel',
  guardianInfoInputRelationshipParent = 'pages.loanApplication.guardianInfoInputRelationshipParent',
  guardianInfoInputRelationshipGrandparent = 'pages.loanApplication.guardianInfoInputRelationshipGrandparent',
  guardianInfoInputRelationshipGuardian = 'pages.loanApplication.guardianInfoInputRelationshipGuardian',
  guardianInfoInputFirstNameLabel = 'pages.loanApplication.guardianInfoInputFirstNameLabel',
  guardianInfoInputLastNameLabel = 'pages.loanApplication.guardianInfoInputLastNameLabel',
  guardianInfoInputMobileLabel = 'pages.loanApplication.guardianInfoInputMobileLabel',
  guardianInfoInputEmailLabel = 'pages.loanApplication.guardianInfoInputEmailLabel',

  guarantorInfoTitle = 'pages.loanApplication.guarantorInfoTitle',
  guarantorInfoSubtitle = 'pages.loanApplication.guarantorInfoSubtitle',
  guarantorInfoInfo = 'pages.loanApplication.guarantorInfoInfo',
  guarantorInfoInputWhoWillHandleThePaymentsLabel = 'pages.loanApplication.guarantorInfoInputWhoWillHandleThePaymentsLabel',
  guarantorInfoGuarantorWillHandleThePaymentsLabel = 'pages.loanApplication.guarantorInfoGuarantorWillHandleThePaymentsLabel',
  guarantorInfoApplicantWillHandleThePaymentsLabel = 'pages.loanApplication.guarantorInfoApplicantWillHandleThePaymentsLabel',
  guarantorInfoApplicantWillHandleThePaymentsNotes = 'pages.loanApplication.guarantorInfoApplicantWillHandleThePaymentsNotes',
  guarantorInfoPersonalDetailsTitle = 'pages.loanApplication.guarantorInfoPersonalDetailsTitle',
  guarantorInfoInputGuardianAsGuarantorLabel = 'pages.loanApplication.guarantorInfoInputGuardianAsGuarantorLabel',
  guarantorInfoInputFirstNameLabel = 'pages.loanApplication.guarantorInfoInputFirstNameLabel',
  guarantorInfoInputLastNameLabel = 'pages.loanApplication.guarantorInfoInputLastNameLabel',
  guarantorInfoInputMobileLabel = 'pages.loanApplication.guarantorInfoInputMobileLabel',
  guarantorInfoInputEmailLabel = 'pages.loanApplication.guarantorInfoInputEmailLabel',
  guarantorInfoInputPlaceOfBirthLabel = 'pages.loanApplication.guarantorInfoInputPlaceOfBirthLabel',
  guarantorInfoInputReligionPlaceholder = 'pages.loanApplication.guarantorInfoInputReligionPlaceholder',
  guarantorInfoInputDependentLabel = 'pages.loanApplication.guarantorInfoInputDependentLabel',
  guarantorInfoInputDependentInfo = 'pages.loanApplication.guarantorInfoInputDependentInfo',
  guarantorInfoInputValidIdLabel = 'pages.loanApplication.guarantorInfoInputValidIdLabel',
  guarantorInfoInputValidIdInfo = 'pages.loanApplication.guarantorInfoInputValidIdInfo',
  guarantorInfoInputValidIdButton = 'pages.loanApplication.guarantorInfoInputValidIdButton',
  guarantorInfoInputSelfieWithValidIdLabel = 'pages.loanApplication.guarantorInfoInputSelfieWithValidIdLabel',
  guarantorInfoInputSelfieWithValidIdInfo = 'pages.loanApplication.guarantorInfoInputSelfieWithValidIdInfo',
  guarantorInfoInputSelfieWithValidIdButton = 'pages.loanApplication.guarantorInfoInputSelfieWithValidIdButton',
  guarantorInfoInputPrivyIdLabel = 'pages.loanApplication.guarantorInfoInputPrivyIdLabel',
  guarantorInfoInputPrivyIdInfo = 'pages.loanApplication.guarantorInfoInputPrivyIdInfo',
  guarantorInfoInputPrivyIdButton = 'pages.loanApplication.guarantorInfoInputPrivyIdButton',

  guarantorInfoEmploymentDetailsTitle = 'pages.loanApplication.guarantorInfoEmploymentDetailsTitle',

  guarantorInfoAddAddressTitle = 'pages.loanApplication.guarantorInfoAddAddressTitle',
  guarantorInfoAddAddressSubtitle = 'pages.loanApplication.guarantorInfoAddAddressSubtitle',
  guarantorAddAddressInfo = 'pages.loanApplication.guarantorAddAddressInfo',
  guarantorInfoInputGuarantorAddressSameAsIdLabel = 'pages.loanApplication.guarantorInfoInputGuarantorAddressSameAsIdLabel',
  guarantorInfoInputPositionPlaceholder = 'pages.loanApplication.guarantorInfoInputPositionPlaceholder',
  guarantorInfoInputIndustryPlaceholder = 'pages.loanApplication.guarantorInfoInputIndustryPlaceholder',
  guarantorInfoInputProofOfIncomeInfo = 'pages.loanApplication.guarantorInfoInputProofOfIncomeInfo',
  guarantorInfoInputProofOfIncomeButton = 'pages.loanApplication.guarantorInfoInputProofOfIncomeButton',

  studentInfoEmploymentDetailsTitle = 'pages.loanApplication.studentInfoEmploymentDetailsTitle',
  studentInfoInputPositionPlaceholder = 'pages.loanApplication.studentInfoInputPositionPlaceholder',
  studentInfoInputIndustryPlaceholder = 'pages.loanApplication.studentInfoInputIndustryPlaceholder',
  studentInfoInputProofOfIncomeInfo = 'pages.loanApplication.studentInfoInputProofOfIncomeInfo',
  studentInfoInputProofOfIncomeButton = 'pages.loanApplication.studentInfoInputProofOfIncomeButton',

  modalUploadValidIdTitle = 'pages.loanApplication.modalUploadValidIdTitle',
  modalUploadValidIdInfo = 'pages.loanApplication.modalUploadValidIdInfo',
  modalUploadValidIdInfo1 = 'pages.loanApplication.modalUploadValidIdInfo1',
  modalUploadValidIdInfo2 = 'pages.loanApplication.modalUploadValidIdInfo2',
  modalUploadValidIdInfo3 = 'pages.loanApplication.modalUploadValidIdInfo3',

  modalUploadProofOfAddressTitle = 'pages.loanApplication.modalUploadProofOfAddressTitle',
  modalUploadProofOfAddressInfo = 'pages.loanApplication.modalUploadProofOfAddressInfo',
  modalUploadProofOfAddressInfo1 = 'pages.loanApplication.modalUploadProofOfAddressInfo1',
  modalUploadProofOfAddressInfo2 = 'pages.loanApplication.modalUploadProofOfAddressInfo2',
  modalUploadProofOfAddressInfo3 = 'pages.loanApplication.modalUploadProofOfAddressInfo3',

  modalUploadAddPrivyIdTitle = 'pages.loanApplication.modalUploadAddPrivyIdTitle',
  modalUploadAddPrivyIdNote = 'pages.loanApplication.modalUploadAddPrivyIdNote',
  modalUploadAddPrivyIdInfo = 'pages.loanApplication.modalUploadAddPrivyIdInfo',

  modalUploadProofOfIncomeTitle = 'pages.loanApplication.modalUploadProofOfIncomeTitle',
  modalUploadProofOfIncomeInfo = 'pages.loanApplication.modalUploadProofOfIncomeInfo',
  modalUploadProofOfIncomeInfo1 = 'pages.loanApplication.modalUploadProofOfIncomeInfo1',
  modalUploadProofOfIncomeInfo2 = 'pages.loanApplication.modalUploadProofOfIncomeInfo2',
  modalUploadProofOfIncomeInfo3 = 'pages.loanApplication.modalUploadProofOfIncomeInfo3',

  modalUploadSelfieTitle = 'pages.loanApplication.modalUploadSelfieTitle',
  modalUploadSelfieInfo = 'pages.loanApplication.modalUploadSelfieInfo',
  modalUploadSelfieInfo1 = 'pages.loanApplication.modalUploadSelfieInfo1',
  modalUploadSelfieInfo2 = 'pages.loanApplication.modalUploadSelfieInfo2',
  modalUploadSelfieInfo3 = 'pages.loanApplication.modalUploadSelfieInfo3',

  modalUploadBorrowerSchoolAssessmentFileTitle = 'pages.loanApplication.modalUploadBorrowerSchoolAssessmentFileTitle',
  modalUploadBorrowerSchoolAssessmentFileInfo = 'pages.loanApplication.modalUploadBorrowerSchoolAssessmentFileInfo',
  modalUploadBorrowerSchoolAssessmentFileInfo1 = 'pages.loanApplication.modalUploadBorrowerSchoolAssessmentFileInfo1',
  modalUploadBorrowerSchoolAssessmentFileInfo2 = 'pages.loanApplication.modalUploadBorrowerSchoolAssessmentFileInfo2',
  modalUploadBorrowerSchoolAssessmentFileInfo3 = 'pages.loanApplication.modalUploadBorrowerSchoolAssessmentFileInfo3',
}

export enum LoanSubmission {
  applicationReviewTitle = 'pages.loanSubmission.applicationReviewTitle',
  applicationReviewSubitle = 'pages.loanSubmission.applicationReviewSubitle',
  requestedAmountLabel = 'pages.loanSubmission.requestedAmountLabel',
  requestedAmountValue = 'pages.loanSubmission.requestedAmountValue',
  requestedAmountInfo = 'pages.loanSubmission.requestedAmountInfo',
  installmentDetails = 'pages.loanSubmission.installmentDetails',
  tuitionFeeLabel = 'pages.loanSubmission.tuitionFeeLabel',
  tuitionFeeValue = 'pages.loanSubmission.tuitionFeeValue',
  monthlyInstallmentsLabel = 'pages.loanSubmission.monthlyInstallmentsLabel',
  monthlyInstallmentsValue = 'pages.loanSubmission.monthlyInstallmentsValue',
  interestPerMonthLabel = 'pages.loanSubmission.interestPerMonthLabel',
  interestPerMonthValue = 'pages.loanSubmission.interestPerMonthValue',
  firstInstallmentDueDateLabel = 'pages.loanSubmission.firstInstallmentDueDateLabel',
  lastInstallmentDueDateLabel = 'pages.loanSubmission.lastInstallmentDueDateLabel',
  paymentPerMonthLabel = 'pages.loanSubmission.paymentPerMonthLabel',
  paymentPerMonthValue = 'pages.loanSubmission.paymentPerMonthValue',
  studentDetails = 'pages.loanSubmission.studentDetails',
  fullName = 'pages.loanSubmission.fullName',
  gender = 'pages.loanSubmission.gender',
  birthday = 'pages.loanSubmission.birthday',
  placeOfBirth = 'pages.loanSubmission.placeOfBirth',
  maritalStatus = 'pages.loanSubmission.maritalStatus',
  mobileNumber = 'pages.loanSubmission.mobileNumber',
  email = 'pages.loanSubmission.email',
  validId = 'pages.loanSubmission.validId',
  address = 'pages.loanSubmission.address',
  nearestLandmark = 'pages.loanSubmission.nearestLandmark',
  proofOfAddress = 'pages.loanSubmission.proofOfAddress',
  guarantorSourceOfIncome = 'pages.loanSubmission.guarantorSourceOfIncome',
  relationshipToStudent = 'pages.loanSubmission.relationshipToStudent',
  guarantorPosition = 'pages.loanSubmission.guarantorPosition',
  guarantorIndustry = 'pages.loanSubmission.guarantorIndustry',
  guarantorProofOfIncome = 'pages.loanSubmission.guarantorProofOfIncome',
  disclaimer = 'pages.loanSubmission.disclaimer',
  guardianInfo = 'pages.loanSubmission.guardianInfo',
  guarantorInfo = 'pages.loanSubmission.guarantorInfo',

  submissionSuccessTitle = 'pages.loanSubmission.submissionSuccessTitle',
  submissionSuccessInfo = 'pages.loanSubmission.submissionSuccessInfo',
  submissionSuccessNotification = 'pages.loanSubmission.submissionSuccessNotification',
  submissionSuccessGoBackToSchoolPortal = 'pages.loanSubmission.submissionSuccessGoBackToSchoolPortal',
}
