import {
  Login,
  CreatePassword,
  Home,
  Eligible,
  Ineligible,
  ExistingUser,
  RequestedAmount,
  SelectLoanProduct,
  LoanApplication,
  LoanSubmission,
} from 'constants/copy/pages'

export const LOGIN_COPY: CopyRecord<Login> = {
  [Login.loginButton]: `Login`,
  [Login.alert]: `Sesi Anda telah berakhir, untuk alasan keamanan Anda harus masuk kembali dengan mengisi halaman ini.`,
}

export const CREATE_PASSWORD_COPY: CopyRecord<CreatePassword> = {
  [CreatePassword.createPasswordButton]: `Set Password`,
  [CreatePassword.heading]: 'Set your password',
}

export const HOME_COPY: CopyRecord<Home> = {
  [Home.title]: 'Dashboard',
  [Home.logoutButton]: 'Logout',
}

export const ELIGIBLE_COPY: CopyRecord<Eligible> = {
  [Eligible.title]: '{{schoolName}} Tuition Installment Plans',
  [Eligible.eligibleInfo]: 'You’re eligible to apply for an installment plan.',
  [Eligible.tenorInfoHeading]: 'Pay in 5 or 12 months!',
  [Eligible.tenorInfoText]:
    'You can now pay your tuition with more flexibility.',
  [Eligible.paymentOptionsInfoHeading]: 'Choose from several payment options.',
  [Eligible.paymentOptionsInfoText]:
    'You can pay via GCASH, Paymaya , Unionbank over the counter, Unionbank online, official Bayad Center branches and through over 3,000 Dragonpay partner channels.',
  [Eligible.thanksForSharingText]: 'Thanks for sharing your info!',
  [Eligible.confirmationHeading]: 'Confirm your details to proceed',
  [Eligible.fullName]: 'Full name',
  [Eligible.mobileNumber]: 'Mobile number',
  [Eligible.notYou]: 'Not you?',
  [Eligible.backToPortal]: 'Go back to school portal',

  [Eligible.modalUpdateMobileTitle]: 'Update your mobile number',
  [Eligible.inputMobileNumberLabel]: 'Mobile number',
  [Eligible.inputMobileNumberButton]: 'Update mobile number',

  [Eligible.modalVerifyPhoneTitle]: 'Verify phone',
  [Eligible.modalVerifyPhoneContent]: `We'll text your verification code to <0>{{phone}}</0>. Standard SMS fees may apply.`,
  [Eligible.modalVerifyPhoneSendVerificationCodeButton]:
    'Send verification code',
  [Eligible.modalVerifyPhoneEditNumberButton]: 'Edit number',

  [Eligible.modalOTPTitle]: 'Enter 6-digit code',
  [Eligible.modalOTPSubtitle]: 'We sent the code to your phone',
  [Eligible.modalOTPContent]: `We sent a 6-digit code to <0>{{phone}}</0>. You'll need to confirm your mobile number to continue.`,
  [Eligible.modalOTPTimerAndResendInfo]: `<0>{{timer}}</0>s to <1>resend</1> code again.`,

  [Eligible.modalUserTypeGuardianTitle]: `Hello, {{name}}!`,
  [Eligible.modalUserTypeGuardianInfo]: `You are applying for an Installment Plan for {{name}}. `,
  [Eligible.modalUserTypeGuardianDetail1]: `∘ Please fill out the succeeding forms with your personal information.`,
  [Eligible.modalUserTypeGuardianDetail2]: `∘ Please remember that it is your responsibility to safeguard your username and password. Bukas will not be held accountable for any liabilities resulting from the unauthorized use of your Bukas account.`,
  [Eligible.modalUserTypeGuardianButton]: `Let’s get started`,
}

export const INELIGIBLE_COPY: CopyRecord<Ineligible> = {
  [Ineligible.title]: '{{schoolName}} Tuition Installment Plans',
  [Ineligible.ineligibleInfo]:
    'Sorry, but you are currently ineligible for an installment plan.',
  [Ineligible.ineligibleDetail]: 'Click here for more details.',
  [Ineligible.tenorInfoHeading]: 'Pay in 5 or 12 months!',
  [Ineligible.tenorInfoText]:
    'You can now pay your tuition with more flexibility.',
  [Ineligible.paymentOptionsInfoHeading]: 'A lot of options to pay',
  [Ineligible.paymentOptionsInfoText]:
    'You can pay via GCASH, Paymaya , Unionbank over the counter, Unionbank online, official Bayad Center branches and through over 3,000 Dragonpay partner channels.',
  [Ineligible.backToSchoolPortal]: 'Go back to school portal',
}

export const EXISTING_USER_COPY: CopyRecord<ExistingUser> = {
  [ExistingUser.title]: '{{schoolName}} Tuition Installment Plans',
  [ExistingUser.welcomeBack]: 'Welcome Back !',
  [ExistingUser.existingUserInfo]: `We found you Bukas account: <0>{{mobileNumber}}</0>. Continue your application using your Bukas account!`,
  [ExistingUser.existingUserWithActiveLoanInfo]: `You already have an activated installment plan with mobile number <0>{{mobileNumber}}</0>. To re-apply, please log in to your Bukas account.`,
  [ExistingUser.existingUserWithPendingLoanInfo]: `Your application with mobile number <0>{{mobileNumber}}</0> is currently being processed. Log in to your Bukas account to learn more about the status.`,
  [ExistingUser.tenorInfoHeading]: 'Pay in 5 or 12 months!',
  [ExistingUser.tenorInfoText]:
    'You can now pay your tuition with more flexibility.',
  [ExistingUser.paymentOptionsInfoHeading]: 'A lot of options to pay',
  [ExistingUser.paymentOptionsInfoText]:
    'You can pay via GCASH, Paymaya , Unionbank over the counter, Unionbank online, official Bayad Center branches and through over 3,000 Dragonpay partner channels.',
  [ExistingUser.goToBALoginPage]: 'Login',
}

export const REQUESTED_AMOUNT_COPY: CopyRecord<RequestedAmount> = {
  [RequestedAmount.title]: 'How much is your tuition fee?',
  [RequestedAmount.label]: 'How much do you need?',
  [RequestedAmount.placeholder]: '20,000.00',
}

export const SELECT_LOAN_PRODUCT_COPY: CopyRecord<SelectLoanProduct> = {
  [SelectLoanProduct.title]:
    'Choose how many months you want to pay for your tuition fee',
  [SelectLoanProduct.recommended]: 'Recommended',
  [SelectLoanProduct.tenor]: 'Pay in {{tenor}} months',
  [SelectLoanProduct.installment]: `<0>P {{installment}}</0> /month`,
  [SelectLoanProduct.interest]: '{{interest}}% interest',
  [SelectLoanProduct.quickAndEasy]: 'Quick and easy application form',
  [SelectLoanProduct.zeroInterest]: 'Zero interest',
  [SelectLoanProduct.longerRepyment]: 'Longer repayment',
  [SelectLoanProduct.lowInterest]: 'Low interest',
  [SelectLoanProduct.summary]: 'Summary of installment',
  [SelectLoanProduct.totalTuitionFee]: 'Total tuition fee amount',
  [SelectLoanProduct.totalTuitionFeeValue]: 'P {{fee}}',
  [SelectLoanProduct.montlyInstallments]: 'Monthly installments',
  [SelectLoanProduct.montlyInstallmentsValue]: '{{tenor}} months',
  [SelectLoanProduct.interestPerMonth]: 'Interest per month',
  [SelectLoanProduct.interestPerMonthValue]: '{{interest}}%',
  [SelectLoanProduct.paymentPerMonth]: 'Payment per month',
  [SelectLoanProduct.paymentPerMonthValue]: 'P {{value}}',
  [SelectLoanProduct.regularInstallment]:
    'Regular Installment ({{tenor}} months)',
}

export const LOAN_APPLICATION_COPY: CopyRecord<LoanApplication> = {
  [LoanApplication.studentDetails]: 'Student Details',
  [LoanApplication.guardianInfo]: 'Guardian Info',
  [LoanApplication.guarantorInfo]: 'Guarantor Info',

  [LoanApplication.studentDetailsTitle]: 'Add your info and submit a valid ID',
  [LoanApplication.studentDetailsSubtitle]:
    'Add your info and submit a valid ID',
  [LoanApplication.studentDetailsInputFirstNameLabel]: 'First name',
  [LoanApplication.studentDetailsInputLastNameLabel]: 'Last name',
  [LoanApplication.studentDetailsInputGenderLabel]: 'Gender',
  [LoanApplication.studentDetailsInputPlaceOfBirthLabel]: 'Place of birth',
  [LoanApplication.studentDetailsInputMaritalStatusLabel]: 'Marital status',
  [LoanApplication.studentDetailsInputReligionPlaceholder]: `Select the student's religion`,
  [LoanApplication.studentDetailsInputEmailLabel]: `Student's email address`,
  [LoanApplication.studentDetailsInputValidIdLabel]: 'Valid ID',
  [LoanApplication.studentDetailsInputValidIdInfo]:
    'You need prepare a valid school ID, or any <0>government-issued documents.<0>',
  [LoanApplication.studentDetailsInputSelfieLabel]: 'Add selfie',
  [LoanApplication.studentDetailsInputSchoolAssessmentLabel]:
    'Add school assessment',
  [LoanApplication.studentDetailsInputValidIdButton]: 'Add a valid ID',
  [LoanApplication.studentDetailsInputSelfieButton]: 'Add selfie',
  [LoanApplication.studentDetailsInputSchoolAssessmentButton]:
    'Add school assessment',
  [LoanApplication.studentDetailsInputPrivyIdLabel]: 'PrivyID number',
  [LoanApplication.studentDetailsInputPrivyIdInfo]:
    'Silahkan masukan PrivyID Pelajar kamu yang sudah terdaftar.',
  [LoanApplication.studentDetailsInputPrivyIdButton]: 'Add a PrivyID number',

  [LoanApplication.studentDetailsAddAddressTitle]: 'Add your current address',
  [LoanApplication.studentDetailsAddAddressSubtitle]:
    'Please make sure to enter the complete current address of the student.',
  [LoanApplication.studentDetailsAddAddressInfo]:
    'Only provide information which is true and correct.',
  [LoanApplication.studentDetailsInputStudentAddressSameAsIdLabel]:
    'Apakah domisili pelajar sama dengan alamat di KTP?',
  [LoanApplication.studentDetailsInputProofOfAddressDocumentLabel]:
    'Proof of address document',
  [LoanApplication.studentDetailsInputProofOfAddressDocumentInfo]:
    'Make sure it matches with the information your provided.',
  [LoanApplication.studentDetailsInputProofOfAddressDocumentButton]:
    'Add a proof of address document',
  [LoanApplication.studentDetailsInputNearestLandmarkLabel]:
    'Nearest landmark at your address',
  [LoanApplication.studentDetailsInputNearestLandmarkPlaceholder]:
    'Input the nearest landmark',
  [LoanApplication.studentDetailsInputNearestLandmarkInfo]:
    'E.g. Luneta Park, Quezon City Circle, Blue Mall',

  [LoanApplication.studentDetailsExpeditedTitle]: 'Add and update your info',
  [LoanApplication.studentDetailsExpeditedSubtitle]:
    'Please make sure to input the correct details.',

  [LoanApplication.guardianInfoTitle]: 'Add your guardian information',
  [LoanApplication.guardianInfoSubtitle]:
    'Please make sure to input the correct details.',
  [LoanApplication.guardianInfoInfo]: `A guardian gives us another possible way to help if you're ever in an urgent situation. We never share info with other people who use Bukas.`,
  [LoanApplication.guardianInfoInputRelationshipLabel]:
    'Relationship to student',
  [LoanApplication.guardianInfoInputRelationshipParent]: 'Parent',
  [LoanApplication.guardianInfoInputRelationshipGrandparent]: 'Grandparent',
  [LoanApplication.guardianInfoInputRelationshipGuardian]: 'Guardian',
  [LoanApplication.guardianInfoInputFirstNameLabel]: `Guardian's first name`,
  [LoanApplication.guardianInfoInputLastNameLabel]: `Guardian's last name`,
  [LoanApplication.guardianInfoInputMobileLabel]: `Guardian's mobile number`,
  [LoanApplication.guardianInfoInputEmailLabel]: `Guardian's email address`,

  [LoanApplication.guarantorInfoTitle]: 'Who will handle the payments?',
  [LoanApplication.guarantorInfoSubtitle]:
    'Please make sure to input the correct details.',
  [LoanApplication.guarantorInfoInfo]:
    'A guarantor is someone who is obliged to handle and take care of payments. A guarantor must have a job and stable income.',
  [LoanApplication.guarantorInfoInputWhoWillHandleThePaymentsLabel]:
    'Who will handle the payments?',
  [LoanApplication.guarantorInfoGuarantorWillHandleThePaymentsLabel]:
    'I have a guarantor who will handle the payments',
  [LoanApplication.guarantorInfoApplicantWillHandleThePaymentsLabel]:
    'I will handle the payments',
  [LoanApplication.guarantorInfoApplicantWillHandleThePaymentsNotes]:
    'FOR WORKING STUDENTS',
  [LoanApplication.guarantorInfoPersonalDetailsTitle]: `Guarantor's Personal Details`,
  [LoanApplication.guarantorInfoInputGuardianAsGuarantorLabel]:
    'My guarantor is the same as my guardian',
  [LoanApplication.guarantorInfoInputFirstNameLabel]: `Guarantor's first name`,
  [LoanApplication.guarantorInfoInputLastNameLabel]: `Guarantor's last name`,
  [LoanApplication.guarantorInfoInputMobileLabel]: `Guarantor's mobile number`,
  [LoanApplication.guarantorInfoInputEmailLabel]: `Guarantor's email address (optional)`,
  [LoanApplication.guarantorInfoInputPlaceOfBirthLabel]: `Guarantor's place of birth`,
  [LoanApplication.guarantorInfoInputReligionPlaceholder]: `Select the guarantor's religion`,
  [LoanApplication.guarantorInfoInputDependentLabel]: 'Number of dependent',
  [LoanApplication.guarantorInfoInputDependentInfo]:
    'Immediate family may include spouses, parents, grandparents, brothers, sisters, sons, and daughters',
  [LoanApplication.guarantorInfoInputValidIdLabel]: `Guarantor's KTP ID`,
  [LoanApplication.guarantorInfoInputValidIdInfo]:
    'You need prepare a valid KTP ID',
  [LoanApplication.guarantorInfoInputValidIdButton]: 'Add a KTP ID',
  [LoanApplication.guarantorInfoInputSelfieWithValidIdLabel]: `Guarantor's selfie with KTP ID`,
  [LoanApplication.guarantorInfoInputSelfieWithValidIdInfo]: `Add your guarantor's selfie KTP ID.`,
  [LoanApplication.guarantorInfoInputSelfieWithValidIdButton]:
    'Selfie with KTP ID',
  [LoanApplication.guarantorInfoInputPrivyIdLabel]: `Guarantor's PrivyID number`,
  [LoanApplication.guarantorInfoInputPrivyIdInfo]:
    'Silahkan masukan PrivyID yang sudah terdaftar.',
  [LoanApplication.guarantorInfoInputPrivyIdButton]: 'Add a PrivyID number',

  [LoanApplication.guarantorInfoEmploymentDetailsTitle]: `Guarantor's Employment Details`,

  [LoanApplication.guarantorInfoAddAddressTitle]: `Add guarantor's current address`,
  [LoanApplication.guarantorInfoAddAddressSubtitle]:
    'Please make sure to enter the complete current address of the guarantor.',
  [LoanApplication.guarantorAddAddressInfo]:
    'Only provide information which is true and correct.',
  [LoanApplication.guarantorInfoInputGuarantorAddressSameAsIdLabel]:
    'Apakah domisili pelajar sama dengan alamat di KTP?',
  [LoanApplication.guarantorInfoInputPositionPlaceholder]: `What is the guarantor's current position?`,
  [LoanApplication.guarantorInfoInputIndustryPlaceholder]: `Select guarantor's current industry`,
  [LoanApplication.guarantorInfoInputProofOfIncomeInfo]:
    'Please prepare proof of income from the guarantor, to support and make it easier for you to fill in the data in the next step',
  [LoanApplication.guarantorInfoInputProofOfIncomeButton]:
    'Add a proof of income document',

  [LoanApplication.studentInfoEmploymentDetailsTitle]:
    'Your Employment Details',
  [LoanApplication.studentInfoInputPositionPlaceholder]:
    'What is your current position?',
  [LoanApplication.studentInfoInputIndustryPlaceholder]:
    'Select your current industry',
  [LoanApplication.studentInfoInputProofOfIncomeInfo]:
    'Please prepare your proof of income',
  [LoanApplication.studentInfoInputProofOfIncomeButton]:
    'Add a proof of income document',

  [LoanApplication.modalUploadValidIdTitle]: 'Add a valid ID',
  [LoanApplication.modalUploadValidIdInfo]: 'When uploading your I.D.',
  [LoanApplication.modalUploadValidIdInfo1]:
    'Make sure the whole ID is inside the frame.',
  [LoanApplication.modalUploadValidIdInfo2]:
    'All information should be clear and visible.',
  [LoanApplication.modalUploadValidIdInfo3]:
    'Only provide information which is true and correct.',

  [LoanApplication.modalUploadProofOfAddressTitle]:
    'Add a Proof of address document',
  [LoanApplication.modalUploadProofOfAddressInfo]:
    'When uploading your proof of address',
  [LoanApplication.modalUploadProofOfAddressInfo1]:
    'Make sure the document is inside the frame.',
  [LoanApplication.modalUploadProofOfAddressInfo2]:
    'All information should be clear and visible.',
  [LoanApplication.modalUploadProofOfAddressInfo3]:
    'Only provide information which is true and correct.',

  [LoanApplication.modalUploadAddPrivyIdTitle]: 'Add your PrivyID',
  [LoanApplication.modalUploadAddPrivyIdNote]:
    'Silahkan masukan PrivyID Pelajar kamu yang sudah terdaftar. Apabila kamu belum memiliki PrivyID Pelajar, silahkan daftarkan PrivyID Pelajar kamu terlebih dahulu.',
  [LoanApplication.modalUploadAddPrivyIdInfo]:
    'Pastikan informasi di bawah ini benar dan akurat.',

  [LoanApplication.modalUploadProofOfIncomeTitle]:
    'Add a proof of income document',
  [LoanApplication.modalUploadProofOfIncomeInfo]:
    'When uploading your proof of income',
  [LoanApplication.modalUploadProofOfIncomeInfo1]:
    'Make sure the document is inside the frame.',
  [LoanApplication.modalUploadProofOfIncomeInfo2]:
    'All information should be clear and visible.',
  [LoanApplication.modalUploadProofOfIncomeInfo3]:
    'Only provide information which is true and correct.',

  [LoanApplication.modalUploadSelfieTitle]: 'Add selfie',
  [LoanApplication.modalUploadSelfieInfo]: 'When uploading your selfie',
  [LoanApplication.modalUploadSelfieInfo1]:
    'Make sure the whole selfie is inside the frame.',
  [LoanApplication.modalUploadSelfieInfo2]:
    'All information should be clear and visible.',
  [LoanApplication.modalUploadSelfieInfo3]:
    'Only provide information which is true and correct.',

  [LoanApplication.modalUploadBorrowerSchoolAssessmentFileTitle]:
    'Add a school asessment document',
  [LoanApplication.modalUploadBorrowerSchoolAssessmentFileInfo]:
    'When uploading your school assessment',
  [LoanApplication.modalUploadBorrowerSchoolAssessmentFileInfo1]:
    'Make sure the document is inside the frame.',
  [LoanApplication.modalUploadBorrowerSchoolAssessmentFileInfo2]:
    'All information should be clear and visible.',
  [LoanApplication.modalUploadBorrowerSchoolAssessmentFileInfo3]:
    'Only provide information which is true and correct.',
}

export const LOAN_SUBMISSION_COPY: CopyRecord<LoanSubmission> = {
  [LoanSubmission.applicationReviewTitle]:
    'Please review all details, {{ name }}.',
  [LoanSubmission.applicationReviewSubitle]:
    'Please check again the installment information listed below before you confirm.',
  [LoanSubmission.requestedAmountLabel]: 'You are requesting for',
  [LoanSubmission.requestedAmountValue]: '{{currency}} {{value}}',
  [LoanSubmission.requestedAmountInfo]: 'Payable within {{tenor}} months',
  [LoanSubmission.installmentDetails]: 'Installment Details',
  [LoanSubmission.tuitionFeeLabel]: 'Total tuition fee amount',
  [LoanSubmission.tuitionFeeValue]: '{{currency}} {{value}}',
  [LoanSubmission.monthlyInstallmentsLabel]: 'Monthly installments',
  [LoanSubmission.monthlyInstallmentsValue]: '{{tenor}} months',
  [LoanSubmission.interestPerMonthLabel]: 'Interest per month',
  [LoanSubmission.interestPerMonthValue]: '{{rate}}%',
  [LoanSubmission.firstInstallmentDueDateLabel]: 'First installment due date',
  [LoanSubmission.lastInstallmentDueDateLabel]: 'Last installment due date',
  [LoanSubmission.paymentPerMonthLabel]: 'Payment per month',
  [LoanSubmission.paymentPerMonthValue]: '{{currency}} {{value}}',
  [LoanSubmission.studentDetails]: 'Student Details',
  [LoanSubmission.fullName]: 'Full name',
  [LoanSubmission.gender]: 'Gender',
  [LoanSubmission.birthday]: 'Birthday',
  [LoanSubmission.placeOfBirth]: 'Place of birth',
  [LoanSubmission.maritalStatus]: 'Marital status',
  [LoanSubmission.mobileNumber]: 'Mobile number',
  [LoanSubmission.email]: 'E-mail address',
  [LoanSubmission.validId]: 'Valid ID',
  [LoanSubmission.address]: 'Full address',
  [LoanSubmission.nearestLandmark]: 'Nearest landmark',
  [LoanSubmission.proofOfAddress]: 'Proof of address document',
  [LoanSubmission.guardianInfo]: 'Guardian Info',
  [LoanSubmission.guarantorInfo]: 'Guarantor Info',
  [LoanSubmission.relationshipToStudent]: 'Relationship to student',
  [LoanSubmission.guarantorSourceOfIncome]: 'Source of Income',
  [LoanSubmission.guarantorPosition]: 'Position',
  [LoanSubmission.guarantorIndustry]: 'Industry',
  [LoanSubmission.guarantorProofOfIncome]: 'Proof of Income',
  [LoanSubmission.disclaimer]: `<0>By submitting this form, you acknowledge that all information provided is accurate, correct and complete and that the documents submitted along with this application form are genuine.</0> <1>You won't be able to edit your information once you submit this form.</1>`,

  [LoanSubmission.submissionSuccessTitle]: `Awesome! We're reviewing your installment application.`,
  [LoanSubmission.submissionSuccessInfo]:
    'Please wait for 2-3 days to process your application.',
  [LoanSubmission.submissionSuccessNotification]: `We'll let you know soon whether it has been approved or if we need more info.`,
  [LoanSubmission.submissionSuccessGoBackToSchoolPortal]:
    'Go back to school portal',
}
