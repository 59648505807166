import {
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_COUNTRY,
  REACT_APP_BA_URL,
} from '../env'

export const IS_PROD = NODE_ENV === 'production'
export const IS_DEV = NODE_ENV !== 'production'

export const API_URL = REACT_APP_API_URL
export const COUNTRY = REACT_APP_COUNTRY
export const BA_URL = REACT_APP_BA_URL

if (!API_URL) {
  throw new Error('Environment variable "REACT_APP_API_URL" not found.')
}

if (!COUNTRY) {
  throw new Error('Environment variable "REACT_APP_COUNTRY" not found.')
}

if (!BA_URL) {
  throw new Error('Environment variable "REACT_APP_BA_URL" not found.')
}

export const IS_PH = COUNTRY === 'PH'
export const IS_ID = COUNTRY === 'ID'
